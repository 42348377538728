export type EmployeeType = 'FT' | 'PT' | 'CON';

export enum UserStatus {
  Inactive = 0,
  Active = 1,
  Offboarded = 2,
  Suspended = 3,
  Draft = 4,
}

export function getUserStatusName(status: number) {
  switch (status) {
    case UserStatus.Inactive:
      return 'Onboarded';
    case UserStatus.Active:
      return 'Active';
    case UserStatus.Offboarded:
      return 'Offboarded';
    case UserStatus.Suspended:
      return 'Suspended';
    case UserStatus.Draft:
      return 'Draft';
    default:
      return '';
  }
}

export enum AccountType {
  SuperAdmin = 100,
  Admin = 200,
  Employee = 300,
  PayrollAdmin = 400,
}

export enum AccountTypeName {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
  Employee = 'employee',
  PayrollAdmin = 'payroll_admin',
}

export const AccountTypeMap = {
  [AccountType.SuperAdmin]: AccountTypeName.SuperAdmin,
  [AccountType.Admin]: AccountTypeName.Admin,
  [AccountType.Employee]: AccountTypeName.Employee,
  [AccountType.PayrollAdmin]: AccountTypeName.PayrollAdmin,
};

export type UserAddress = {
  place_id?: string;
  city?: string;
  street?: string;
  country?: string;
  country_iso?: string;
  postal_code?: string;
  street_number?: string;
};

export type PersonalAddress = {
  street?: string;
  city?: string;
  postal_code?: string;
};
