import { FC } from 'react';
import clx from 'classnames';

type SpinnerSize = 'sm' | 'md';

type SpinnerProps = {
  color?: string;
  className?: string;
  size?: SpinnerSize;
};

const sizeToDimension = {
  md: 14,
  sm: 6,
};

export const LoadingSpinner: FC<SpinnerProps> = ({
  color,
  className,
  size = 'md',
}) => {
  const dimension = sizeToDimension[size];

  return (
    <div className="z-20">
      <svg
        className={clx(
          `animate-spin -ml-1 h-${dimension} w-${dimension} text-brand-primary-500`,
          color,
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};
