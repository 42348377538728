import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/navigation';
import { UserSessionData } from 'lib/prisma/queries/user/get-user-session-data';
import { getLoginPageUrl } from 'lib/url-helper/auth';
import storage from 'lib/storage';
import { PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY } from 'lib/constants';
import { AuthPages } from '../url-helper/routes';

type UseSessionProps = {
  redirectToLogin?: boolean;
};

type UserSessionStatus = 'loading' | 'authenticated' | 'unauthenticated';

export function useSession({ redirectToLogin = true }: UseSessionProps = {}) {
  const router = useRouter();
  const [state, setState] = useState<{
    loading: boolean;
    status: UserSessionStatus;
    session: UserSessionData | null;
    error: any;
  }>({
    loading: true,
    status: 'loading',
    session: null,
    error: null,
  });

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await fetch('/api/v1/auth/get-session');
        const data = await res.json();

        if (data.authenticated) {
          Sentry.setUser({
            userId: data.session.id,
            companyId: data.session.company_id,
          });
        } else {
          Sentry.setUser(null);
        }

        setState({
          loading: false,
          status: data.authenticated ? 'authenticated' : 'unauthenticated',
          session: data.session,
          error: null,
        });
      } catch (error) {
        console.error('Error fetching session:', error);
        setState({
          loading: false,
          status: 'unauthenticated',
          session: null,
          error,
        });
        Sentry.setUser(null);
      }
    };

    fetchSession();
  }, []);

  useEffect(() => {
    if (state.status === 'unauthenticated' && redirectToLogin) {
      if (!window.location.pathname.startsWith(AuthPages.Login)) {
        storage.setItem(
          PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY,
          window.location.href,
        );
      }
      router.push(getLoginPageUrl());
    }
  }, [state.status, redirectToLogin, router]);

  return state;
}
