interface IBus {
  bus: Record<string, Function>;
}

export class Bus implements IBus {
  bus;

  constructor() {
    this.bus = {};
  }

  $off(id) {
    delete this.bus[id];
  }

  $on(id, callback) {
    this.bus[id] = callback;
  }

  $emit(id, ...params) {
    if (id in this.bus) {
      this.bus[id](...params);
    }
  }
}

export const EventBus = new Bus();
