'use client';
import { FC, useState, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { Transition } from '@headlessui/react';
import clx from 'classnames';

import collapsedLogo from '../../../public/assets/logo/givver-collapsed.svg';
import extendedLogo from '../../../public/assets/logo/givver-extended.svg';

import { useSession } from 'lib/hooks/session';
import { menuElements } from './constants';
import { usePathname } from 'next/navigation';

export type MenuItemsProps = {
  open: boolean;
};

const MenuItems: FC<MenuItemsProps> = ({ open }) => {
  const [showLabels, setShowLabels] = useState(false);
  const [showLabelsTimeout, setShowLabelsTimeout] = useState<NodeJS.Timeout>();
  const { session } = useSession();
  const pathname = usePathname();

  function isActive(href: string) {
    return pathname === href;
  }

  const menuList = useMemo(
    () =>
      menuElements.filter(({ permissions }) =>
        permissions.includes(session?.account_type),
      ),
    [session?.account_type],
  );

  useEffect(() => {
    if (open) {
      const t = setTimeout(() => {
        setShowLabels(true);
      }, 150);
      setShowLabelsTimeout(t);
    }

    if (!open) {
      setShowLabels(false);
    }

    return () => {
      clearTimeout(showLabelsTimeout);
    };
  }, [open]);

  useEffect(() => {
    if (open) return;

    setShowLabels(false);
  }, [showLabels]);

  return (
    <div className={clx('transition ease-in-out duration-200')}>
      {menuList.map(({ title, href, Icon }) => (
        <Link
          key={title}
          href={href}
          passHref
          style={{ height: 56 }}
          className={clx(
            'relative flex items-center mb-8 py-4 w-full transition ease-in duration-150 hover:bg-gray-50',
            {
              'hover:bg-white': isActive(href),
              'justify-center pl-0': !open,
              'justify-start pl-8': open,
            },
          )}
        >
          <div
            className={clx(
              'transition ease-in-out duration-200 absolute left-0 top-0 bg-brand-primary-500 rounded-r-full w-1 opacity-0 h-full',
              {
                'opacity-100': isActive(href),
              },
            )}
          />
          <Icon
            className={clx('w-5 transition ease-in-out duration-200', {
              'fill-current text-brand-primary-500': isActive(href),
              'fill-current text-brand-gray-700': !isActive(href),
              'mr-6': open,
            })}
            color={isActive(href) ? '#00E59D' : '#B8B8B8'}
          />
          <span
            style={{
              transition: 'ease 0.2s',
            }}
            className={clx('font-bold', {
              'text-brand-gray-600': !isActive(href),
              'text-black': isActive(href),
              'opacity-100': showLabels,
              'opacity-0 hidden': !showLabels,
            })}
          >
            {title}
          </span>
        </Link>
      ))}
    </div>
  );
};

export const NavigationDrawer: FC = () => {
  const [open, setOpen] = useState(false);
  const [showExtendedLogo, setShowExtendedLogo] = useState(false);
  const [showCollapsedLogo, setShowCollapsedLogo] = useState(true);
  const [extendedLogoTimeout, setExtendedLogoTimeout] =
    useState<NodeJS.Timeout>(null);
  const [collapsedLogoTimeout, setCollapsedLogoTimeout] =
    useState<NodeJS.Timeout>(null);

  useEffect(() => {
    const t1 = setTimeout(
      () => {
        setShowExtendedLogo(open);
      },
      open ? 300 : 150,
    );

    const t2 = setTimeout(
      () => {
        setShowCollapsedLogo(!open);
      },
      open ? 150 : 300,
    );

    setExtendedLogoTimeout(t1);
    setCollapsedLogoTimeout(t2);

    return () => {
      clearTimeout(extendedLogoTimeout);
      clearTimeout(collapsedLogoTimeout);
    };
  }, [open]);

  useEffect(() => {
    if (showCollapsedLogo !== showExtendedLogo || open) return;

    setShowExtendedLogo(false);
  }, [showCollapsedLogo, showExtendedLogo]);

  return (
    <div
      style={{
        transition: '0.2s ease',
      }}
      className={clx('fixed left-0 top-0 h-full bg-white shadow-md z-10', {
        'w-24': !open,
        'w-52': open,
      })}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className="flex justify-center pt-7">
        <>
          <Transition
            show={showCollapsedLogo}
            enter="transition ease-in-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-400"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Link href="/company" passHref legacyBehavior>
              <button className="p-3 bg-brand-gray-300 rounded-full w-14 h-14 opacity-100">
                <img src={collapsedLogo.src} className="w-full h-full" />
              </button>
            </Link>
          </Transition>
          <Transition
            show={showExtendedLogo}
            enter="transition ease-in-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-400"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Link href="/company" passHref legacyBehavior>
              <button className="p-3 h-14">
                <img src={extendedLogo.src} className="w-32 opacity-100" />
              </button>
            </Link>
          </Transition>
        </>
      </div>
      <hr className="my-14 border-t w-7/12 m-auto border-gray-100" />
      <MenuItems open={open} />
    </div>
  );
};
