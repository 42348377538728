import { IconProps } from 'lib/types';

export default function PayrollIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0769 19.1707C6.58873 19.6245 7.26219 19.9 8 19.9H20.25C21.8516 19.9 23.15 18.6016 23.15 17V10C23.15 8.39837 21.8516 7.1 20.25 7.1H17.7904L18.2727 8.9H20.25C20.8575 8.9 21.35 9.39249 21.35 10V17C21.35 17.6075 20.8575 18.1 20.25 18.1H10.3992L6.65611 19.103C6.46234 19.1549 6.2676 19.1765 6.0769 19.1707Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.40194 10.6986C0.987411 9.15155 1.9055 7.56138 3.45255 7.14685L14.9099 4.07686C16.4569 3.66233 18.0471 4.58042 18.4616 6.12747L20.3971 13.3505C20.8116 14.8976 19.8935 16.4878 18.3465 16.9023L6.88912 19.9723C5.34207 20.3868 3.75189 19.4687 3.33736 17.9217L1.40194 10.6986ZM3.91843 8.88551C3.33161 9.04275 2.98337 9.64592 3.14061 10.2327L5.07603 17.4558C5.23326 18.0426 5.83643 18.3909 6.42324 18.2336L17.8806 15.1636C18.4674 15.0064 18.8156 14.4032 18.6584 13.8164L16.723 6.59334C16.5657 6.00653 15.9626 5.65829 15.3758 5.81553L3.91843 8.88551Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45984 9.4922C6.5005 9.43278 6.5314 9.41863 6.54722 9.4144L13.3087 7.60266C13.3245 7.59842 13.3584 7.59522 13.4233 7.62635C13.4906 7.65864 13.5672 7.71734 13.6411 7.79473C13.9823 8.15181 14.504 8.31766 15.0164 8.18036L15.4994 8.05095C15.5527 8.03666 15.6076 8.06831 15.6219 8.12166L16.9159 12.9513C16.9302 13.0046 16.8986 13.0595 16.8452 13.0738L16.3623 13.2032C15.8498 13.3405 15.481 13.745 15.364 14.2248C15.3387 14.3288 15.3018 14.4179 15.2596 14.4795C15.2189 14.5389 15.188 14.5531 15.1722 14.5573L8.41072 16.3691C8.3949 16.3733 8.36107 16.3765 8.29614 16.3454C8.2288 16.3131 8.15227 16.2544 8.07832 16.177C7.73712 15.8199 7.21544 15.6541 6.70301 15.7914L6.22005 15.9208C6.1667 15.9351 6.11187 15.9034 6.09758 15.8501L4.80348 11.0204C4.78919 10.9671 4.82085 10.9123 4.87419 10.898L5.35715 10.7685C5.86958 10.6312 6.23844 10.2268 6.35539 9.74693C6.38073 9.64293 6.41766 9.55384 6.45984 9.4922ZM7.22158 10.7584C7.0601 11.0264 6.85065 11.2654 6.60163 11.4613C6.43496 11.5924 6.26794 11.8494 6.35082 12.1587L6.86846 14.0906C6.95134 14.3999 7.22451 14.539 7.4344 14.5692C7.74799 14.6143 8.04886 14.7166 8.32271 14.8679C8.52363 14.9789 8.77512 15.0291 9.02706 14.9616L13.9347 13.6466C14.1866 13.5791 14.3794 13.4099 14.4978 13.2133C14.6593 12.9453 14.8688 12.7063 15.1178 12.5104C15.2845 12.3793 15.4515 12.1223 15.3686 11.813L14.851 9.88113C14.7681 9.57183 14.4949 9.43272 14.285 9.40252C13.9714 9.35739 13.6706 9.25517 13.3967 9.10383C13.1958 8.99278 12.9443 8.94263 12.6924 9.01014L7.78472 10.3251C7.53279 10.3926 7.34007 10.5618 7.22158 10.7584Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7913 12.7035C11.2104 12.7035 11.5501 12.3638 11.5501 11.9447C11.5501 11.5256 11.2104 11.1859 10.7913 11.1859C10.3722 11.1859 10.0325 11.5256 10.0325 11.9447C10.0325 12.3638 10.3722 12.7035 10.7913 12.7035ZM10.7913 13.2035C11.4865 13.2035 12.0501 12.6399 12.0501 11.9447C12.0501 11.2495 11.4865 10.6859 10.7913 10.6859C10.0961 10.6859 9.53247 11.2495 9.53247 11.9447C9.53247 12.6399 10.0961 13.2035 10.7913 13.2035Z"
      />
      <path d="M8.82886 12.6776C8.82886 12.9538 8.605 13.1776 8.32886 13.1776C8.05272 13.1776 7.82886 12.9538 7.82886 12.6776C7.82886 12.4015 8.05272 12.1776 8.32886 12.1776C8.605 12.1776 8.82886 12.4015 8.82886 12.6776Z" />
      <path d="M13.8096 11.343C13.8096 11.6192 13.5857 11.843 13.3096 11.843C13.0334 11.843 12.8096 11.6192 12.8096 11.343C12.8096 11.0669 13.0334 10.843 13.3096 10.843C13.5857 10.843 13.8096 11.0669 13.8096 11.343Z" />
    </svg>
  );
}
