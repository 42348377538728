import { FC } from 'react';
import Link from 'next/link';
import { LogoutIcon, BellIcon } from '@heroicons/react/outline';

export const NavBar: FC = () => {
  return (
    <div className="flex justify-end items-center w-full">
      <button className="mr-10">
        <BellIcon className="w-5 h-5" />
      </button>
      <Link
        href="/api/v1/auth/logout"
        className="flex items-center font-bold hover:underline"
      >
        Log out
        <LogoutIcon className="w-5 h-5 ml-2" />
      </Link>
    </div>
  );
};
