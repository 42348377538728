import {
  SessionProvider,
  useSessionContext,
} from '@/lib/providers/session-context';
import { LoadingScreen } from 'components/atoms/LoadingScreen';
import { GlobalNotificationBar } from 'components/atoms/NotificationBar';
import { defaultConfig } from 'components/misc/Meta';
import { NavBar } from 'components/molecules/NavBar';
import { NavigationDrawer } from 'components/organisms/NavigationDrawer';
import 'flag-icons/css/flag-icons.min.css';
import { AccountType } from 'lib/types/user';
import { getDefaultRoute } from 'lib/url-helper/routes';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'reflect-metadata';
import 'tailwindcss/tailwind.css';
import { IntercomChat } from '../components/atoms/IntercomChat';
import '../styles/custom.css';
import { useEffect } from 'react';
import storage from '@/lib/storage';
import { PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY } from '@/lib/constants';

function PrivateOnlyWrapper({ children }) {
  const { loading, status, session } = useSessionContext();
  const router = useRouter();

  useEffect(() => {
    if (!loading && status === 'unauthenticated') {
      const currentPath = window.location.href;
      // Only save the return URL if we're not already on a public route
      if (!currentPath.includes('/auth/')) {
        storage.setItem(PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY, currentPath);
      }
      router.push('/auth/login');
    }
  }, [loading, status, router]);

  if (loading) {
    return <LoadingScreen show={true} />;
  }

  if (status === 'unauthenticated') {
    return null;
  }

  const account_type = session.account_type || '';

  // TODO improve with a FORBIDDEN page or similar
  if (
    router.asPath.includes('/payroll') &&
    AccountType.PayrollAdmin !== account_type &&
    AccountType.SuperAdmin !== account_type
  ) {
    return null;
  }

  return (
    <>
      <NavigationDrawer />
      <div className="relative min-h-full w-full pl-36 pr-16">
        <div className="pt-10 mb-14">
          <NavBar />
        </div>
        {children}
      </div>
      <IntercomChat session={session} />
    </>
  );
}

function PublicOnlyWrapper({ children }) {
  const { status, loading } = useSessionContext();
  const router = useRouter();

  useEffect(() => {
    if (!loading && status === 'authenticated') {
      const savedReturnUrl = storage.getItem(
        PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY,
      );

      if (savedReturnUrl) {
        storage.removeItem(PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY);
        // Ensure the saved URL is for our domain before redirecting
        try {
          const url = new URL(savedReturnUrl);
          if (url.origin === window.location.origin) {
            window.location.href = savedReturnUrl;
            return;
          }
        } catch (e) {
          console.error('Invalid saved return URL:', e);
        }
      }
      // Fall back to default route if no valid saved URL
      window.location.href = getDefaultRoute();
    }
  }, [loading, status, router]);

  if (loading || status === 'authenticated') {
    return null;
  }

  return children;
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isPublicRoute = router.asPath.startsWith('/auth/');

  return (
    <>
      <div className="relative w-full min-h-screen bg-gray-100 overflow-x-hidden">
        <Head>
          <link
            rel="icon"
            type="image/png"
            sizes="16×16"
            href="/assets/favicons/16x16.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32×32"
            href="/assets/favicons/32x32.png"
          />
        </Head>
        <NextSeo {...defaultConfig} />
        <SessionProvider key={router.asPath}>
          {isPublicRoute ? (
            <PublicOnlyWrapper>
              <Component {...pageProps} />
            </PublicOnlyWrapper>
          ) : (
            <PrivateOnlyWrapper>
              <Component {...pageProps} />
            </PrivateOnlyWrapper>
          )}
        </SessionProvider>
      </div>
      <GlobalNotificationBar />
    </>
  );
}

export default MyApp;
