import store from 'store2';

export class LocalBrowserStorage implements Storage {
  get length() {
    return store.length;
  }

  clear(): void {
    store.clear();
  }

  getItem(key: string): string | null {
    return store.get(key);
  }

  key(index: number): string | null {
    return store.keys()?.[index];
  }

  removeItem(key: string): void {
    store.remove(key);
  }

  setItem(key: string, value: string): void {
    store.set(key, value);
  }
}
export default new LocalBrowserStorage();
