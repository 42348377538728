export enum ApiV1 {
  Payroll = '/api/v1/payroll',
}

export enum PayrollApis {
  ApproveEmployees = '/approve-employees',
  GetCompaniesToOpenPayrollFor = '/get-companies-to-open-payroll-for',
  GetCompanyPayrolls = '/get-company-payrolls',
  GetEmployeeBreakdown = '/get-employee-breakdown',
  People = '/people',
  UnapproveEmployees = '/unapprove-employees',
  Summary = '/get-summary',
  SyncCompanyPayroll = '/sync-company-payroll',
}

export enum Pages {
  Calendar = '/calendar',
  Company = '/company',
  Leaves = '/leaves',
  Private = '/private',
  Payroll = '/payroll',
  PayrollSummary = '/payroll/summary',
  Profile = '/profile',
}

export enum AuthPages {
  Admin = '/auth/admin',
  CreatePassword = '/create-password',
  ForgotPassword = '/forgot-password',
  Login = '/auth/login',
}

export enum LeavesPages {
  ManageApproval = '/manage-approval',
}

export enum PrivatePages {
  LeavesEngineSimulator = '/leaves-engine-simulator',
}

export function getDefaultRoute() {
  return Pages.Company;
}
