import { LoadingSpinner } from '@/components/atoms/LoadingSpinner';
import { useSession } from '@/lib/hooks/session';
import React, { useEffect, useMemo, useState } from 'react';

export const SessionContext = React.createContext(null);

export function SessionProvider({ children }) {
  const sessionData = useSession({ redirectToLogin: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sessionData.status !== 'loading') {
      setLoading(false);
    }
  }, [sessionData.status]);

  const memoizedSessionValue = useMemo(() => sessionData, [sessionData]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SessionContext.Provider value={memoizedSessionValue}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
}
