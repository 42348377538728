import { IconProps } from '@/lib/types';

export default function ReportingIcon(props: IconProps) {
  const color = props.color || '#B8B8B8';
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99988 0.75C8.80096 0.75 8.6102 0.829018 8.46955 0.96967C8.3289 1.11032 8.24988 1.30109 8.24988 1.5V9C8.24988 9.41421 8.58566 9.75 8.99988 9.75H16.4999C16.9141 9.75 17.2499 9.41421 17.2499 9C17.2499 7.91659 17.0365 6.8438 16.6219 5.84286C16.2073 4.84192 15.5996 3.93245 14.8335 3.16637C14.0674 2.40029 13.158 1.7926 12.157 1.37799C11.1561 0.963393 10.0833 0.75 8.99988 0.75ZM9.74988 8.25V2.2918C10.3788 2.36211 10.996 2.52068 11.583 2.76381C12.4019 3.10303 13.1461 3.60023 13.7728 4.22703C14.3996 4.85382 14.8968 5.59794 15.2361 6.41689C15.4792 7.00387 15.6378 7.62106 15.7081 8.25H9.74988Z"
        fill={color}
      />
      <path
        d="M6.29978 2.80989C6.67942 2.64422 6.85288 2.20216 6.68721 1.82252C6.52154 1.44288 6.07948 1.26942 5.69984 1.43509C4.46472 1.97408 3.38044 2.80742 2.54178 3.86226C1.70313 4.91711 1.13564 6.16133 0.888937 7.48616C0.642233 8.81099 0.72382 10.1761 1.12657 11.4621C1.52932 12.7481 2.24096 13.9159 3.19929 14.8633C4.15761 15.8108 5.33344 16.509 6.62396 16.8971C7.91449 17.2851 9.28043 17.3511 10.6024 17.0893C11.9243 16.8275 13.1619 16.2458 14.2071 15.3952C15.2523 14.5445 16.0732 13.4508 16.5981 12.2096C16.7594 11.8281 16.5809 11.388 16.1994 11.2267C15.8179 11.0654 15.3779 11.2439 15.2165 11.6254C14.7871 12.6409 14.1155 13.5358 13.2603 14.2318C12.4051 14.9277 11.3925 15.4037 10.3109 15.6179C9.22935 15.8321 8.11177 15.7781 7.05588 15.4606C6 15.1431 5.03796 14.5718 4.25387 13.7966C3.46979 13.0215 2.88753 12.066 2.55801 11.0138C2.22849 9.96161 2.16174 8.84472 2.36359 7.76077C2.56544 6.67682 3.02974 5.65881 3.71591 4.79576C4.40209 3.93271 5.28923 3.25088 6.29978 2.80989Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99988 0.75C8.80096 0.75 8.6102 0.829018 8.46955 0.96967C8.3289 1.11032 8.24988 1.30109 8.24988 1.5V9C8.24988 9.41421 8.58566 9.75 8.99988 9.75H16.4999C16.9141 9.75 17.2499 9.41421 17.2499 9C17.2499 7.91659 17.0365 6.8438 16.6219 5.84286C16.2073 4.84192 15.5996 3.93245 14.8335 3.16637C14.0674 2.40029 13.158 1.7926 12.157 1.37799C11.1561 0.963393 10.0833 0.75 8.99988 0.75ZM9.74988 8.25V2.2918C10.3788 2.36211 10.996 2.52068 11.583 2.76381C12.4019 3.10303 13.1461 3.60023 13.7728 4.22703C14.3996 4.85382 14.8968 5.59794 15.2361 6.41689C15.4792 7.00387 15.6378 7.62106 15.7081 8.25H9.74988Z"
        stroke={color}
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29978 2.80989C6.67942 2.64422 6.85288 2.20216 6.68721 1.82252C6.52154 1.44288 6.07948 1.26942 5.69984 1.43509C4.46472 1.97408 3.38044 2.80742 2.54178 3.86226C1.70313 4.91711 1.13564 6.16133 0.888937 7.48616C0.642233 8.81099 0.72382 10.1761 1.12657 11.4621C1.52932 12.7481 2.24096 13.9159 3.19929 14.8633C4.15761 15.8108 5.33344 16.509 6.62396 16.8971C7.91449 17.2851 9.28043 17.3511 10.6024 17.0893C11.9243 16.8275 13.1619 16.2458 14.2071 15.3952C15.2523 14.5445 16.0732 13.4508 16.5981 12.2096C16.7594 11.8281 16.5809 11.388 16.1994 11.2267C15.8179 11.0654 15.3779 11.2439 15.2165 11.6254C14.7871 12.6409 14.1155 13.5358 13.2603 14.2318C12.4051 14.9277 11.3925 15.4037 10.3109 15.6179C9.22935 15.8321 8.11177 15.7781 7.05588 15.4606C6 15.1431 5.03796 14.5718 4.25387 13.7966C3.46979 13.0215 2.88753 12.066 2.55801 11.0138C2.22849 9.96161 2.16174 8.84472 2.36359 7.76077C2.56544 6.67682 3.02974 5.65881 3.71591 4.79576C4.40209 3.93271 5.28923 3.25088 6.29978 2.80989Z"
        stroke={color}
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
