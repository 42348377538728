import { VFC } from 'react';
import { Transition } from '@headlessui/react';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export type LoadingScreenProps = {
  show?: boolean;
};

const loadingScreenDefaultProps = {
  show: false,
};

export const LoadingScreen: VFC<LoadingScreenProps> = ({
  show,
} = loadingScreenDefaultProps) => (
  <Transition
    show={show}
    enter="transition ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition ease-in duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={
        'flex justify-center items-center w-full min-h-screen order-2 fixed top-0 left-0 z-50 bg-gray-100'
      }
    >
      <LoadingSpinner color="text-brand-accent-500" />
    </div>
  </Transition>
);
