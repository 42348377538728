'use client';
import { FC, useState } from 'react';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import clx from 'classnames';
import { Transition } from '@headlessui/react';
import { EventBus } from '../../../lib/utils/bus';

export type NotificationStatus = 'success' | 'error' | 'warning' | 'info';

export type NotificationBarProps = {
  status?: NotificationStatus;
  label: string;
  className?: string;
};

function Icon(status: NotificationBarProps['status']) {
  const iconClasses = 'w-5 mr-2';

  switch (status) {
    case 'success':
      return <CheckCircleIcon className={clx(iconClasses, 'text-green-500')} />;
    case 'error':
      return <XCircleIcon className={clx(iconClasses, 'text-red-500')} />;
    case 'warning':
      return (
        <ExclamationCircleIcon
          className={clx(iconClasses, 'text-yellow-500')}
        />
      );
    default:
    case 'info':
      return (
        <InformationCircleIcon className={clx(iconClasses, 'text-blue-400')} />
      );
  }
}

export const NotificationBar: FC<NotificationBarProps> = ({
  status,
  label,
  className,
}) => {
  return (
    <div
      className={clx(
        'flex items-center justify-center bg-black px-5 py-3 text-white border border-gray-600 rounded-md w-96 text-sm',
        className,
      )}
      style={{
        zIndex: 99999999,
      }}
    >
      {Icon(status)} {label}
    </div>
  );
};

export function GlobalNotificationBar() {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<NotificationStatus>();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [show, setShow] = useState(false);

  EventBus.$on('show-global-notification', (data: any) => {
    if (show) {
      clearTimeout(timer);
    }

    setMessage(data.message);
    setStatus(data.status as NotificationStatus);
    setShow(true);

    setTimer(
      setTimeout(() => {
        setShow(false);
      }, 3000),
    );
  });

  return (
    <Transition
      show={show}
      className="fixed left-0 bottom-10 w-full flex justify-center z-50"
      enter="transition ease-out duration-300"
      enterFrom="transform translate-y-10 opacity-0"
      enterTo="transform -translate-y-0 opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform translate-y-10 opacity-0"
      style={{
        zIndex: 99999,
      }}
    >
      <NotificationBar label={message} status={status} />
    </Transition>
  );
}
