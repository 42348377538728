export default function CompanyIcon({
  className,
  color = 'black',
  size = 20,
  sizeH = 20,
  sizeW = 20,
  viewBox = '0 0 20 20',
}: {
  className?: string;
  color?: string;
  sizeH?: number;
  sizeW?: number;
  size?: number;
  viewBox?: string;
}) {
  if (size && !sizeH) sizeH = size;
  if (size && !sizeW) sizeW = size;
  return (
    <svg
      width={sizeW}
      height={sizeH}
      viewBox={viewBox}
      fill={color ?? 'none'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.3358 20C4.64146 20 4.07084 19.4738 4.00611 18.8009L4 18.6731V10.7911C4 10.1014 4.52977 9.53465 5.20716 9.47036L5.3358 9.46429L7.595 9.464L7.59551 6.32686C7.59551 5.63716 8.12527 5.07037 8.80266 5.00607L8.93131 5H15.0687C15.8064 5 16.4045 5.59405 16.4045 6.32686L16.404 9.479L18.6565 9.46731C19.3508 9.46336 19.9245 9.9863 19.9931 10.6588L20 10.7942V18.6581C20 19.3454 19.4738 19.9111 18.799 19.9783L18.6708 19.9849L16.404 19.996L16.4045 20H5.3358ZM7.595 11.071L5.61798 11.0714V18.3929L7.595 18.392V11.071ZM14.7865 6.60714H9.21348V18.3929H14.7865V6.60714ZM18.382 11.0759L16.404 11.087V18.388L18.382 18.3795V11.0759Z"
        fill={color ?? '#333333'}
      />
    </svg>
  );
}
