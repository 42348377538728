import { IconProps } from 'lib/types';

export default function ProfileIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M12.0842 11.319C12.1975 10.8807 12.6568 10.6141 13.1101 10.7237L15.8999 11.3982C16.9094 11.6423 17.6626 12.4576 17.8002 13.4551L17.9737 14.7135C17.9912 14.8407 18 14.9689 18 15.0972C18 16.7004 16.6561 18 14.9984 18H3.00195C2.86928 18 2.73676 17.9915 2.60525 17.9745C0.96205 17.7627 -0.192416 16.3027 0.0266774 14.7135L0.200183 13.4551C0.337712 12.4576 1.09091 11.6423 2.10043 11.3982L4.89023 10.7237C5.34353 10.6141 5.80287 10.8807 5.91619 11.319C6.02952 11.7574 5.75392 12.2016 5.30062 12.3112L2.51081 12.9857C2.17431 13.0671 1.92324 13.3388 1.8774 13.6713L1.70389 14.9298C1.6083 15.6231 2.11198 16.2601 2.82888 16.3525C2.88625 16.3599 2.94407 16.3636 3.00195 16.3636H14.9984C15.7216 16.3636 16.3079 15.7966 16.3079 15.0972C16.3079 15.0412 16.3041 14.9853 16.2965 14.9298L16.1229 13.6713C16.0771 13.3388 15.826 13.0671 15.4895 12.9857L12.6997 12.3112C12.2464 12.2016 11.9708 11.7574 12.0842 11.319ZM9.00017 0C11.3555 0 13.2649 1.84652 13.2649 4.12433C13.2649 4.30662 13.2524 4.48872 13.2275 4.66941L12.786 7.87148C12.533 9.70648 10.9144 11.0769 9.00017 11.0769C7.08592 11.0769 5.46735 9.70648 5.21436 7.87148L4.77289 4.66941C4.4616 2.41159 6.10187 0.337222 8.43653 0.036179C8.62338 0.0120862 8.81167 0 9.00017 0ZM10.7695 2.81877C10.1797 3.16337 9.58995 3.33566 9.00017 3.33566C8.41038 3.33566 7.82059 3.16336 7.23079 2.81874C7.11314 2.75005 6.969 2.7444 6.84634 2.80371L6.80862 2.8211C6.50878 3.29205 6.36862 3.86218 6.4501 4.45315L6.89157 7.65521C7.03248 8.67726 7.93398 9.44056 9.00017 9.44056C10.0664 9.44056 10.9679 8.67726 11.1088 7.65521L11.5502 4.45315C11.5653 4.34414 11.5728 4.2343 11.5728 4.12433C11.5728 3.64703 11.4338 3.20112 11.1929 2.82239L11.154 2.8037C11.0313 2.74437 10.8872 2.75003 10.7695 2.81877Z" />
    </svg>
  );
}
