'use client';
import { UserSessionData } from 'lib/prisma/queries/user/get-user-session-data';
import { useEffect } from 'react';

export function IntercomChat({ session }: { session?: UserSessionData }) {
  useEffect(() => {
    if (session) {
      // @ts-ignore
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'rtpkl2yp',
        user_id: session.id,
        external_id: session.id,
        email: session.corporate_email,
        company: {
          company_id: session.company_id,
        },
        Account_type: session.account_type,
      };

      const w = window;
      // @ts-ignore
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        // @ts-ignore
        ic('update', intercomSettings);
      } else {
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        // @ts-ignore
        w.Intercom = i;

        const l = function () {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/rtpkl2yp';
          const x = document.getElementsByTagName('script')[0];
          // @ts-ignore
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
          // @ts-ignore
        } else if (w.attachEvent) {
          // @ts-ignore
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    }
  }, [session]);

  return <></>;
}
